<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Pengaturan Bukaan Pasaran</h3>
  <div class="columns is-multiline text-left mb-6">
    <div class="column is-12">
      <div class="field">
        <label class="label">Id Pasaran</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.id" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">Bukaan</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Bukaan"
            v-model="inputData.bukaan"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Prize 1</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Bukaan"
            v-model="inputData.prize1"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Prize 2</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Bukaan"
            v-model="inputData.prize2"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Prize 3</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Bukaan"
            v-model="inputData.prize3"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Jenis Pasaran</label>
        <div class="control">
          <div class="select">
            <select v-model="inputData.kode_jenis_pasaran">
              <option
                v-for="item in providerList"
                :key="item.kode_pasaran"
                :value="item.kode_pasaran"
              >
                {{item.jenis_pasaran}} ({{item.kode_pasaran}})
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Tanggal Buka</label>
        <div class="control">
          <datepicker
            ref="beginDateElementRef"
            v-model="pickedDate"
            class="input"
            :upperLimit="new Date()"
          />
        </div>
        <div class="control">
          <div class="columns mt-4">
            <div class="column">
              <label class="label">Jam Buka</label>
              <input
                class="input"
                type="number"
                placeholder="Jam"
                max="23"
                v-model="jamBuka.jam"
              />
            </div>
            <div class="column">
              <label class="label">Menit Buka</label>
              <input
                class="input"
                type="number"
                placeholder="Menit"
                max="59"
                v-model="jamBuka.menit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <button
        class="button is-danger mr-1"
        @click="cancelEdit()"
        v-bind:disabled="!editButtonStatus"
      >
        Batal Edit
      </button>
      <button class="button is-success" @click="doSave()">Simpan Data</button>
    </div>
  </div>

  <div class="mt-6">
    <Pagination :pagination="pagination" @paginate:goto="updatePage">
      <div class="table-container">
        <table
          class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left"
        >
          <thead>
            <tr>
              <th>Aksi</th>
              <th>Jenis Pasaran</th>
              <th>Bukaan</th>
              <th>prize1</th>
              <th>prize2</th>
              <th>prize3</th>
              <th>Waktu Buka</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Aksi</th>
              <th>Jenis Pasaran</th>
              <th>Bukaan</th>
              <th>prize1</th>
              <th>prize2</th>
              <th>prize3</th>
              <th>Waktu Buka</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(data, index) in tableData" :key="index">
              <td class="has-text-centered">
                <button class="button is-success mr-1" @click="doEdit(data)">
                  Edit
                </button>
                <button class="button is-danger" @click="doDelete(data)">
                  Hapus
                </button>
              </td>
              <td>{{data.kode_jenis_pasaran}}</td>
              <td>{{data.bukaan}}</td>
              <td>{{data.prize1}}</td>
              <td>{{data.prize2}}</td>
              <td>{{data.prize3}}</td>
              <td>
                {{new Date(data.waktu_buka).toLocaleString('en-US', { weekday:
                'long', day: 'numeric', month: 'long', year: 'numeric', hour:
                '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC'
                })}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Pagination>
  </div>
</div>
