<template>
  <div class="columns">
    <div class="column is-12"><slot></slot></div>
  </div>

  <div class="columns text-center">
    <div class="column is-12">
      <nav class="pagination" role="navigation" aria-label="pagination">
        <a
          class="pagination-previous"
          :class="{
            'is-disabled': pagination.currentPage == pagination.firstPage,
          }"
          @click="prevPage()"
          >Previous</a
        >
        <a
          class="pagination-next"
          :class="{
            'is-disabled': pagination.currentPage == pagination.lastPage,
          }"
          @click="nextPage()"
          >Next page</a
        >
        <ul class="pagination-list">
          <li
            v-for="(n, index) in pagination.lastPage"
            :key="index"
            @click="gotoPage(n)"
          >
            <a
              class="pagination-link"
              :class="{ 'is-current': pagination.currentPage == n }"
              href="#"
            >
              {{ n }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  props: {
    pagination: Object,
  },
  setup(props, context) {
    const pagination = ref({
      page: 1,
      limit: 10,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });
    onMounted(() => {
      pagination.value = props.pagination;
    });
    const gotoPage = (num) => {
      pagination.value.page = num;
      context.emit("paginate:goto", pagination.value.page);
    };
    const prevPage = () => {
      if (pagination.value.page != pagination.value.firstPage) {
        pagination.value.page--;
        context.emit("paginate:goto", pagination.value.page);
      }
    };
    const nextPage = () => {
      if (pagination.value.page != pagination.value.lastPage) {
        pagination.value.page++;
        context.emit("paginate:goto", pagination.value.page);
      }
    };

    return { onMounted, gotoPage, prevPage, nextPage };
  },
};
</script>

<style></style>
