import { ref, onMounted } from "vue";
import { handleError } from "@/utils/errorHandler";
import ProviderService from "@/services/provider_services";
import GameService from "@/services/game_services";
import Pagination from "@/components/PaginationTable.vue";
import Datepicker from "vue3-datepicker";

export default {
  name: "Game Admin",
  components: { Pagination, Datepicker },
  setup(props, context) {
    const inputData = ref({
      id: undefined,
      bukaan: undefined,
      prize1: undefined,
      prize2: undefined,
      prize3: undefined,
      waktu_buka: Date.now(),
      kode_jenis_pasaran: undefined,
    });

    const beginDateElementRef = ref(null);
    const pickedDate = ref(new Date());

    const jamBuka = ref({
      jam: new Date().getHours(),
      menit: 0,
    });

    const editButtonStatus = ref(false);

    const providerList = ref([{ kode_pasaran: "", jenis_pasaran: "" }]);

    const tableData = ref([
      {
        id: undefined,
        bukaan: undefined,
        waktu_buka: undefined,
        kode_jenis_pasaran: undefined,
        jenis_pasaran: {
          kode_pasaran: undefined,
          jenis_pasaran: undefined,
        },
      },
    ]);

    const pagination = ref({
      page: 1,
      limit: 40,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });

    const updatePage = (value) => {
      pagination.value.page = value;
      fetchData();
    };

    const fetchData = async () => {
      try {
        let tmpProviders = await ProviderService.getAll(1, 9999);
        providerList.value = tmpProviders.data.data.rows;

        let response = await GameService.getAll(
          pagination.value.page,
          pagination.value.limit
        );
        tableData.value = response.data.data.rows;
        pagination.value.firstPage = response.data.data.firstPage;
        pagination.value.lastPage = response.data.data.lastPage;
        pagination.value.currentPage = response.data.data.currentPage;

        // console.log("TABLE DATA:::", tableData.value);
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const doEdit = (data) => {
      inputData.value = { ...data };
      editButtonStatus.value = true;
    };

    const cancelEdit = () => {
      inputData.value.id = undefined;
      editButtonStatus.value = false;
    };

    const doDelete = async (data) => {
      let confirmation = confirm(
        `Yakin menghapus data ${data.provider}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await GameService.deleteData(data.id);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doSave = () => {
      if (!inputData.value.bukaan && !inputData.value.prize1) {
        alert("Minimal bukaan atau prize1 harus diisi");
        return;
      }

      pickedDate.value.setHours(jamBuka.value.jam);
      pickedDate.value.setMinutes(jamBuka.value.menit);
      inputData.value.waktu_buka = pickedDate.value.getTime();
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        if (inputData.value.id) {
          updateExistingData();
        } else {
          createNewData();
        }
        cancelEdit();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        console.log("DATA :::", inputData.value);
        let response = await GameService.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    const updateExistingData = async () => {
      try {
        let response = await GameService.updateData(
          inputData.value.id,
          inputData.value
        );
        if (response.data.success) {
          fetchData();
          alert("Data berhasil diubah");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    return {
      inputData,
      tableData,
      providerList,
      pagination,
      editButtonStatus,
      pickedDate,
      beginDateElementRef,
      jamBuka,
      updatePage,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,
    };
  },
};
