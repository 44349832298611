import BaseServices from "./base_services";
import Axios from "axios";
const RESOURCE_NAME = "/pasaran";

class PasaranServices extends BaseServices {
  constructor(moduleName) {
    super(moduleName);
  }

  getGamesByProviderId(providerId) {
    return Axios.get(
      `${this.RESOURCE_NAME}/by_jenis_pasaran/${providerId}?limit=200`
    );
  }
}

export default new PasaranServices(RESOURCE_NAME);
